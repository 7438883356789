<template>
    <div class="c3-content-customer-registration">
        <div class="c3-content-customer-registration-description">
            <c3-text-with-icon icon="c3-icon-clock" :title="$t('pages.customer.registration.openingTimes.title')">
                <p>{{ $t('pages.customer.registration.openingTimes.text') }}</p>
            </c3-text-with-icon>
        </div>
        <div class="c3-content-customer-registration-description-form">
            <div class="c3-content-customer-registration-title">
                <c3-title-with-subtitle :title="$t('label.step2Of2')" :subtitle="$t('pages.customer.registration.openingTimes.subtitle')"/>
            </div>
            <c3-form @submit="submit" styling="dark">
                <c3-customer-opening-time :hasOpeningTimes.sync="store.hasOpeningTimes" :openingTimes.sync="store.openingTimes"/>
                <c3-form-text :label="$t('customer.openingTimesComment')" v-model="store.openingTimesComment" :validator="$v.store.openingTimesComment"
                              :maxLength="openingTimesCommentMaxLength"/>

                <c3-form-submit :label="$t('label.closeBtn')" styling="action"/>
                <div v-if="$v.$error" class="c3-form-field-group-error-message">{{ $t('error.invalidOpeningHours') }}</div>
            </c3-form>
        </div>
    </div>
</template>

<script>
    import storeFactory from '@/domain/factory/store';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';
    import C3CustomerOpeningTime from '@/components/form/fields/customer/OpeningTime';

    const FIELDS = ['hasOpeningTimes', 'openingTimes', 'openingTimesComment'];

    const NEXT_STEP = {name: 'CustomerRegistrationFinished'};

    export default {
        components: {
            C3TextWithIcon,
            C3CustomerOpeningTime,
            C3TitleWithSubtitle
        },
        data() {
            return {
                store: storeFactory.create(FIELDS),
                openingTimesCommentMaxLength: USER.OPENING_TIMES_COMMENT_LENGTH,
            };
        },
        methods: {
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                this.$store.commit('view/showLoading');
                try {
                    const data = {...this.store};
                    if (!this.store.id) {
                        // Fill store data from user. This allows us to just ask once for the address, phone number and email during the company registration
                        // The user can later change the store fields independent from the user fields inside his profile
                        const user = this.$store.getters['user/user'];
                        data.phone = user.companyPhone;
                        data.email = user.companyEmail;
                        storeFactory.fill(['address.street', 'address.zip', 'address.city', 'address.country'], data, user.billingAddress);
                        await this.$store.dispatch('userStore/createStore', data);
                    } else {
                        await this.$store.dispatch('userStore/updateStore', data);
                    }

                    await this.$store.dispatch('user/enableCustomerRole');
                    await this.$router.push(NEXT_STEP);
                } catch (error) {
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                    console.error(error);
                }
                this.$store.commit('view/hideLoading');
            },
            async initialFillUserFromStore() {
                this.$store.commit('view/showLoading');

                try {
                    await Promise.all([
                        this.$store.dispatch('userStore/loadStore'),
                        this.$store.dispatch('user/loadCurrentUser')
                    ]);
                    const stores = this.$store.getters['userStore/currentUserStoreListOrderByZip'];
                    if (stores.length > 0) {
                        // The customer shouldn't have more than one store during the wizard, just use the first store to prefill all fields
                        storeFactory.fill(
                            ['id', 'address.street', 'address.zip', 'address.city', 'address.country', 'phone', 'email', ...FIELDS],
                            this.store,
                            stores[0]
                        );
                    }
                } catch (error) {
                    console.error(error);
                }

                this.$store.commit('view/hideLoading');
            }
        },
        validations: {
            store: storeFactory.createValidation(FIELDS)
        },
        created() {
            this.initialFillUserFromStore();
        },
        activated() {
            this.initialFillUserFromStore();
        }
    };
</script>
