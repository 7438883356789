export const StripeMixin = {
    props: {
        stripeElements: Object,
        value: Object
    },
    data() {
        return {
            errorMessage: '',
            field: null,

            iconStyle: 'solid',
            fieldStyle: {
                base: {
                    iconColor: COLORS.QUINARY,
                    color: COLORS.SENARY,
                    fontWeight: 400,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '14px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: COLORS.SENARY
                    },
                    ':-webkit-autofill': {
                        color: COLORS.SENARY
                    }
                },
                invalid: {
                    iconColor: COLORS.QUINARY,
                    color: COLORS.TERTIARY
                }
            }
        }
    },
    methods: {
        handleChange(event) {
            console.debug('Stripe field change event', event);
            if (event.error) {
                this.errorMessage = event.error.message;
            } else {
                this.errorMessage = '';
            }

            if (event.complete) {
                this.$emit('input', this.field);
                this.handleComplete(event);
            } else {
                this.$emit('input', null);
            }
        },
        handleComplete(event) {
            console.debug('Stripe payment data complete', event);
            this.$emit('complete');
        },
    },
    mounted() {
        this.field = this.stripeElements.create(this.paymentType, {
            iconStyle: this.iconStyle,
            style: this.fieldStyle
        });

        this.field.mount(this.$refs.stripeElement);
        this.$emit('input', this.field);
        this.field.addEventListener('change', this.handleChange);
    },
    beforeDestroy() {
        if (null !== this.field) {
            this.field.destroy();
        }
    }
};