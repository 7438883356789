<template>
    <c3-form @submit="submit">
        <template v-if="isStripeReady">
            <div class="c3-form-group c3-form-group-payment">
                <c3-form-select :options="paymentOptions" v-model="paymentDetails.selectedPayment" :validator="$v.paymentDetails.selectedPayment"
                                :label="$t('label.paymentType')" :placeholder="$t('label.pleaseSelectOption')" @input="onMethodChange"/>
                <c3-form-input v-if="showOwnerField" :label="nameLabel" v-model="paymentDetails.ownerName" :validator="$v.paymentDetails.ownerName"/>
                <template v-if="stripeElements">
                    <c3-card v-if="paymentDetails.selectedPayment === 'card' && !isIOS" v-model="paymentDetails.paymentData" :stripeElements="stripeElements"/>
                    <template v-if="paymentDetails.selectedPayment ==='iban'">
                        <c3-form-input label="IBAN" v-model="paymentDetails.iban" :validator="$v.paymentDetails.iban"/>
                        <c3-form-switch v-model="paymentDetails.sepaDirectDebitMandate" :validator="$v.paymentDetails.sepaDirectDebitMandate">
                            <template slot="labelBefore">
                                {{ $t('customer.payment.sepaMandate') }}
                            </template>
                        </c3-form-switch>
                    </template>
                </template>
                <c3-form-submit :label="$t(buttonLabel)" styling="action"/>
                <div v-if="$v.$error" class="c3-form-field-group-error-message">{{ $t('error.invalidPaymentDetails') }}</div>
            </div>
        </template>
        <c3-loader v-else :hCenter="true"/>
    </c3-form>
</template>

<script>
    import {Config} from '@/config';
    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';
    import {InvalidPaymentMethodException} from '@/domain/exception/invalidPaymentMethodException';
    import paymentFactory from '@/domain/factory/payment';
    import userFactory from '@/domain/factory/user';

    import C3Card from '@/components/form/fields/stripe/Card.vue';
    import C3Iban from '@/components/form/fields/stripe/Iban.vue';

    const USER_FIELDS = ['id'];

    export default {
        mixins: [FormFieldErrorMixin],
        components: {
            C3Card,
            C3Iban
        },
        props: {
            buttonLabel: {
                type: String,
                default: 'label.savePayment'
            }
        },
        data() {
            return {
                isStripeReady: false,
                user: userFactory.create(USER_FIELDS),
                paymentData: null,

                paymentDetails: paymentFactory.create(),
                paymentOptions: paymentFactory.createPaymentOptions(),
                stripe: null,
                stripeElements: null,
            }
        },
        computed: {
            nameLabel() {
                switch (this.paymentDetails.selectedPayment) {
                    case PAYMENT.TYPE.CREDIT_CARD:
                        return this.$t('label.cardHolder');
                    case PAYMENT.TYPE.IBAN:
                        return this.$t('label.accountHolder');
                }
                return this.$t('label.holder');
            },
            showOwnerField() {
                if (this.isIOS && this.paymentDetails.selectedPayment === PAYMENT.TYPE.CREDIT_CARD) {
                    return false;
                }
                return !!this.paymentDetails.selectedPayment;
            },
        },
        methods: {
            reset() {
                this.paymentData = null;
                this.$v.$reset();
                this.paymentDetails = paymentFactory.create();
            },
            async onMethodChange() {
                if ((this.isIOS) && this.paymentDetails.selectedPayment === PAYMENT.TYPE.CREDIT_CARD) {
                    this.$store.commit('view/showLoading', this.$t('pages.customer.profile.payment.loadingMessage'));
                    var intentUrl = this.$store.getters['userPayment/setupIntentUrl']('stripe')
                    var token = this.$store.getters['user/token']
                    var publicKey = Config.getStripeKey()
                    
                    try {
                        var result = await window.plugin.Stripe.showCardPaymentInterface(publicKey, intentUrl, token);
                        result = JSON.parse(result)
                        this.paymentData = {
                            provider: 'stripe',
                            type: this.paymentDetails.selectedPayment,
                            ownerName: result.ownerName,
                            data: {
                                setupIntent: result.id, 
                                paymentMethod: result.paymentId
                            },
                        };
                    } catch (e) {
                        this.reset();
                        if (e != 'SHEET_CLOSED') {
                            this.$c3.handleErrorsWithAlert(e);
                        }
                        console.error('[PaymentDetailsForm->submitToNativeStripeAPI]', JSON.stringify(e));
                        this.$store.commit('view/hideLoading');
                        return;
                    }

                    try {
                        await this.$store.dispatch('user/updatePaymentData', Object.assign({}, this.paymentData));

                        this.$c3.successAlert(this.$t('success.paymentDataUpdated.title'), this.$t('success.paymentDataUpdated.message'));
                        this.reset();
                        this.$emit('updated');
                    } catch (e) {
                        console.error('[PaymentDetailsForm->updatePaymentData]', JSON.stringify(e));
                        this.$c3.handleErrorsWithAlert(e);
                    }
                    this.$store.commit('view/hideLoading');
                }
            },
            async submit() {
                if (this.paymentDetails.selectedPayment === PAYMENT.TYPE.IBAN) {
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }

                    this.$store.commit('view/showLoading', this.$t('pages.customer.profile.payment.loadingMessage'));

                    this.paymentData = {
                        provider: 'iban',
                        type: this.paymentDetails.selectedPayment,
                        ownerName: this.paymentDetails.ownerName,
                        data: {iban: this.paymentDetails.iban, sepaDirectDebitMandate: this.paymentDetails.sepaDirectDebitMandate},
                    };

                    try {
                        await this.$store.dispatch('user/updatePaymentData', Object.assign({}, this.paymentData))
                        this.$c3.successAlert(this.$t('success.paymentDataUpdated.title'), this.$t('success.paymentDataUpdated.message'));
                        this.reset();
                        this.$emit('updated');
                    } catch (e) {
                        console.error('[PaymentDetailsForm->submit] IBAN', JSON.stringify(e));
                        this.$c3.handleErrorsWithAlert(e);
                    }
                    this.$store.commit('view/hideLoading');
                } else {
                    this.$store.commit('view/showLoading', this.$t('pages.customer.profile.payment.loadingMessage'));
                    try {
                        await this.submitToStripe();
                    } catch (e) {
                        this.$v.$touch();
                        console.error('[PaymentDetailsForm->submitToStripe]', JSON.stringify(e));
                        this.$c3.handleErrorsWithAlert(e);
                        this.$store.commit('view/hideLoading');
                        return;
                    }

                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        this.$store.commit('view/hideLoading');
                        return;
                    }

                    try {
                        await this.$store.dispatch('user/updatePaymentData', Object.assign({}, this.paymentData));

                        this.$c3.successAlert(this.$t('success.paymentDataUpdated.title'), this.$t('success.paymentDataUpdated.message'));
                        this.reset();
                        this.$emit('updated');
                    } catch (e) {
                        console.error('[PaymentDetailsForm->updatePaymentData]', JSON.stringify(e));
                        this.$c3.handleErrorsWithAlert(e);
                    }
                    this.$store.commit('view/hideLoading');

                }
            },
            async submitToStripe() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                // Load setupIntent clientSecret
                let intent = await this.$store.dispatch('userPayment/loadIntent', 'stripe');
                // Setup card /
                let result = await this.stripe.handleCardSetup(
                    intent,
                    this.paymentDetails.paymentData,
                    {
                        payment_method_data: {
                            billing_details: {
                                name: this.paymentDetails.ownerName
                            }
                        }
                    }
                );

                if (result.error) {
                    throw new InvalidPaymentMethodException('', result.error.message);
                }

                this.paymentData = {
                    provider: 'stripe',
                    type: this.paymentDetails.selectedPayment,
                    ownerName: this.paymentDetails.ownerName,
                    data: {setupIntent: result.setupIntent.id, paymentMethod: result.setupIntent.payment_method},
                };
            },
            fillBillingAddressFromStore() {
                this.$store.commit('view/showLoading');
                this.$store.dispatch('user/loadCurrentUser')
                    .finally(() => {
                        let user = this.$store.getters['user/user'];
                        this.user.id = user.id;
                        USER_FIELDS.forEach(field => {
                            this.user[field] = user[field];
                        });

                        this.$store.commit('view/hideLoading');
                    });
            },
            initStripe() {
                this.stripe = Stripe(Config.getStripeKey(), {locale: 'de'});
                this.stripeElements = this.stripe.elements();
            }
        },
        created() {
            this.fillBillingAddressFromStore();
        },
        mounted() {
            if (typeof Stripe === 'function') {
                this.initStripe();
                this.isStripeReady = true;
            } else {
                let script = document.createElement('script');
                script.onload = () => {
                    this.initStripe();
                    this.isStripeReady = true;
                };
                script.async = true;
                script.src = 'https://js.stripe.com/v3/';
                document.head.appendChild(script);
            }
        },
        validations: {
            user: userFactory.createValidation(USER_FIELDS),
            paymentDetails: paymentFactory.createValidation()
        },
    };
</script>
<style lang="scss" scoped>
    .c3-loader {
        margin-top: 15px;
    }

    .c3-form-field-group-error-message {
        margin-top: 10px;
    }
</style>
