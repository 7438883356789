<template>
    <div class="c3-badge-box">
        <div class="c3-badge-box-badge" v-if="badgeText">
            <span class="c3-badge-box-badge-content" :class="badgeTextClass">{{badgeText}}</span>
        </div>
        <div class="c3-badge-box-content">
            <h2 v-if="title">{{title}}</h2>
            <div class="c3-badge-box-content-text">
                <slot>{{content}}</slot>
            </div>
            <c3-button v-if="buttonText" :label="buttonText" @click="$emit('click')"/>
            <div v-if="buttonAdditionalInformation" class="c3-form-field-additional-information-small">{{buttonAdditionalInformation}}</div>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {C3Button},
        props: {
            title: {
                type: String
            },
            badgeText: {
                type: String
            },
            badgeTextClass: {
                type: String
            },
            content: {
                type: String
            },
            buttonText: {
                type: String
            },
            buttonAdditionalInformation: {
                type: String
            }
        },
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    .c3-badge-box {
        position: relative;
        overflow: hidden;
        background-color: $secondary-color;
    }

    .c3-badge-box-badge {
        // TODO: Replace static position values by formula
        position: absolute;
        top: 80px;
        left: -25px;
        width: 150px;
        transform: rotate(-45deg);
        transform-origin: 0 0;
        padding: 5px 0;
        background-color: $white-color;
        text-align: center;
    }

    .c3-badge-box-badge-content {
        transform: translateZ(0);
        @include openSansBold();
        font-size: 12px;
    }

    .c3-badge-box-content {
        max-width: 280px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
    }

    h2 {
        color: $quinary-color;
        margin-bottom: 15px;
    }

    .c3-badge-box-content-text {
        padding-bottom: 15px;
    }
</style>