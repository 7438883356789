<template>
    <div class="c3-form-field-group c3-form-field-group-credit-iban c3-form-field-group-stripe">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-credit-iban c3-form-field-wrapper-stripe-payment">
            <label for="paymentElement">IBAN</label>
            <div id="paymentElement" class="input" ref="paymentElement"></div>
        </div>
        <div class="c3-form-field-group-error-message" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
    import {StripeMixin} from './mixin/stripe';

    export default {
        mixins: [StripeMixin],
        mounted() {
            this.field = this.stripeElements.create('iban', {
                supportedCountries: ['SEPA'],
                placeholderCountry: 'AT',
                iconStyle: this.iconStyle,
                style: this.fieldStyle
            });
            this.field.mount(this.$refs.paymentElement);
            this.$emit('input', this.field);
            this.field.addEventListener('change', (event) => {
                if (event.error) {
                    this.errorMessage = event.error.message;
                } else {
                    this.errorMessage = '';
                }
                this.$emit('input', this.field);
            });
        },
    };
</script>
<style lang="scss" scoped>
</style>