import {CoupocketException} from './coupocketException';

export let InvalidPaymentMethodException = function (title, message) {
    this.message = message;
    this.stack = (new Error()).stack;
    this.getTitle = () => title;
    this.getMessage = () => message;
};

InvalidPaymentMethodException.prototype = Object.create(CoupocketException.prototype);
InvalidPaymentMethodException.prototype.name = "InvalidPaymentMethodException";
InvalidPaymentMethodException.prototype.constructor = InvalidPaymentMethodException;
