<template>
    <div class="c3-content-membership-select">
        <div class="c3-voucher">
            <form class="c3-voucher__form" @submit.prevent="redeemVoucher">
                <c3-form-input v-model="voucherCode" :placeholder="$t('label.couponCode')" class="c3-voucher__form-input"/>
                <c3-form-submit :label="$t('label.redeem')" styling="action" class="c3-voucher__form-submit"/>
            </form>
        </div>
        <template v-for="(membershipType,index) in membershipTypes">
            <c3-badge-box :title="membershipType.title" :badgeText="membershipType.badgeText" :badgeTextClass="index % 2 ? 'c3-text-color-tertiary':'c3-text-color-quinary'"
                          :buttonText="membershipType.buttonText" :buttonAdditionalInformation="membershipType.buttonAdditionalInformation"
                          @click="alert(membershipType)">
                <span v-html="membershipType.description"></span>
                <template v-if="membershipType.discountDescription">
                    <br><span class="c3-membership-discount-description">{{membershipType.discountDescription}}</span>
                </template>
            </c3-badge-box>
        </template>
        <div class="c3-page-content-padding" v-if="faqItems && faqItems.length">
            <c3-text-with-icon icon="c3-icon-check-circle" :title="$t('pages.customer.profile.membership.faqTitle')"/>
            <c3-faq>
                <template v-for="item in faqItems">
                    <c3-faq-item v-if="item" :id="item.id" :title="item.title" :content="item.text"/>
                </template>
            </c3-faq>
        </div>
    </div>
</template>

<script>
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3BadgeBox from '@/components/layout/badgeBox/Index';

    import C3Faq from '@/components/layout/faq/Index';
    import C3FaqItem from '@/components/layout/faq/Item';

    export default {
        components: {
            C3TextWithIcon,
            C3BadgeBox,
            C3Faq,
            C3FaqItem
        },
        props: {
            membershipTypes: {
                type: Array,
                required: true
            },
            faqItems: {
                type: Array
            }
        },
        data() {
            return {
                voucherCode: ''
            }
        },
        computed: {},
        methods: {
            alert(membershipType) {
                this.$c3.confirmYesNo(
                    membershipType.title,
                    this.$t('confirm.membership.text', { title: membershipType.title }),
                    () => this.submit(membershipType.id)
                );
            },
            submit(type) {
                const instantUpdate = !!this.$route.query.instantUpdate;
                this.$store.commit('view/showLoading');
                this.$store.dispatch('user/updateMembership', { type, instantUpdate })
                    .then(() => {
                        this.$store.commit('view/hideLoading');
                        this.$emit('submitted');
                    })
                    .catch(error => {
                        this.$store.commit('view/hideLoading');
                        this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                        console.error(error);
                    });
            },
            redeemVoucher() {
                if (!this.voucherCode) {
                    this.$c3.errorAlert('', 'error.invalidVoucher');
                    return;
                }

                this.$store.commit('view/showLoading', this.$t('voucher.loadingRedeem'));
                this.$store.dispatch('voucher/redeem', this.voucherCode)
                    .then(() => this.$store.dispatch('membership/loadAvailableType', this.$store.getters['user/user'].id))
                    .then(() => this.$c3.successAlert('voucher.redeemSuccessful.title', 'voucher.redeemSuccessful.message'))
                    .catch(this.$c3.handleErrorsWithAlert)
                    .finally(() => this.$store.commit('view/hideLoading'))
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-content-padding .c3-content-text-with-icon {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;

        & /deep/ h2 {
            margin-top: 5px;
        }
    }

    .c3-voucher {
        margin-bottom: 15px;
        padding: 30px 15px;
        background-color: $secondary-color;
    }

    .c3-voucher__form {
        display: flex;
        max-width: 280px;

        margin-right: auto;
        margin-left: auto;
    }

    .c3-voucher__form-input {
        margin-top: 0;

        & /deep/ .c3-form-field-input {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .c3-voucher__form-submit {
        width: 120px;
        margin-top: 0;

        & /deep/ .c3-form-field-button {
            @include openSansSemiBold();
            font-size: 14px;

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .c3-voucher__title {
        @include openSansBold();
        font-size: 16px;
        text-align: center;
    }

    .c3-badge-box {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .c3-membership-discount-description {
        font-size: 12px;
        color: $quinary-color;
    }
</style>
