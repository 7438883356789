<template>
    <div class="c3-content-customer-registration">
        <div class="c3-content-customer-registration-description">
            <c3-text-with-icon icon="c3-icon-thumbs-up" :title="$t('pages.customer.registration.company.title')">
                <p>{{ $t('pages.customer.registration.company.message') }}</p>
            </c3-text-with-icon>
        </div>
        <div class="c3-content-customer-registration-description-form">
            <div class="c3-content-customer-registration-title">
                <c3-title-with-subtitle :title="$t('label.step1Of2')" :subtitle="$t('pages.customer.registration.company.button')"/>
            </div>

            <c3-form @submit="submit" styling="dark">
                <c3-form-input :label="$t('customer.company')" v-model="$v.user.company.$model" :validator="$v.user.company"/>
                <c3-form-input :label="$t('customer.vatId')" v-model.trim="$v.user.vatId.$model" :validator="$v.user.vatId"/>
                <c3-form-input :label="$t('customer.address')" v-model="$v.billingAddress.address.street.$model" :validator="$v.billingAddress.address.street"/>
                <c3-form-input :label="$t('customer.zip')" v-model="$v.billingAddress.address.zip.$model" :validator="$v.billingAddress.address.zip"/>
                <c3-form-input :label="$t('customer.city')" v-model="$v.billingAddress.address.city.$model" :validator="$v.billingAddress.address.city"/>
                <c3-form-select :label="$t('customer.country')" v-model="$v.billingAddress.address.country.$model" :options="countryOptions"
                                :placeholder="$t('label.pleaseSelectOption')"/>
                <c3-form-input :label="$t('customer.companyPhone.label')"
                               type="tel"
                               v-model="$v.user.companyPhone.$model"
                               :validator="$v.user.companyPhone"
                               :description="$t('customer.companyPhone.description')"
                />
                <c3-form-input :label="$t('customer.companyEmail')" type="email" v-model="$v.user.companyEmail.$model" :validator="$v.user.companyEmail"/>
                <c3-form-input-url :label="$t('customer.website')" v-model="$v.user.website.$model" :validator="$v.user.website"/>
                <c3-form-submit :label="$t('label.continueToStep2')" styling="action"/>
            </c3-form>
        </div>
    </div>
</template>

<script>
    import billingAddressFactory from '@/domain/factory/billingAddress';
    import userFactory from '@/domain/factory/user';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import phoneNumberService from '@/domain/service/phoneNumberService';

    const USER_FIELDS = ['company', 'companyPhone', 'companyEmail', 'website', 'vatId'];
    const BILLING_ADDRESS_FIELDS = ['address.street', 'address.zip', 'address.city', 'address.country'];
    const NEXT_STEP = {name: 'CustomerRegistrationOpeningTime'};

    export default {
        components: {
            C3TextWithIcon,
            C3TitleWithSubtitle
        },
        data() {
            return {
                user: userFactory.create(USER_FIELDS),
                billingAddress: billingAddressFactory.create(BILLING_ADDRESS_FIELDS),
            };
        },
        computed: {
            countryOptions() {
                return this.$store.getters['staticContent/countrySelectOptions'];
            }
        },
        methods: {
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$store.commit('view/showLoading');
                try {
                    const user = {...this.user};
                    user.companyPhone = phoneNumberService.sanitizeWithoutPrefix(user.companyPhone);

                    const billingAddress = {...this.billingAddress};
                    billingAddress.company = this.user.company;

                    await Promise.all([
                        this.$store.dispatch('user/updateFields', user),
                        this.$store.dispatch('user/updateBillingAddress', billingAddress)
                    ]);
                    this.$store.commit('view/hideLoading');
                    this.$router.push(NEXT_STEP);
                } catch (error) {
                    this.$store.commit('view/hideLoading');
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                    console.error(error);
                }
            },
            async initialFillUserFromStore() {
                this.$store.commit('view/showLoading');
                try {
                    await Promise.all([
                        this.$store.dispatch('staticContent/loadCountries'),
                        this.$store.dispatch('user/loadCurrentUser')
                    ]);

                    let user = this.$store.getters['user/user'];
                    this.user.id = user.id;
                    USER_FIELDS.forEach(field => {
                        this.user[field] = user[field];
                    });

                    if (user.hasOwnProperty('billingAddress')) {
                        billingAddressFactory.fill(BILLING_ADDRESS_FIELDS, this.billingAddress, user.billingAddress);
                    }
                } catch (error) {
                    console.error('Prefill user or billing address failed', error);
                }
                this.$store.commit('view/hideLoading');
            }
        },
        validations: {
            user: userFactory.createValidation(USER_FIELDS),
            billingAddress: billingAddressFactory.createValidation(BILLING_ADDRESS_FIELDS),
        },
        created() {
            this.initialFillUserFromStore();
        },
        activated() {
            this.initialFillUserFromStore();
        }
    };
</script>
