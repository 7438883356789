<template>
    <div class="c3-form-field-group c3-form-field-group-credit-card c3-form-field-group-stripe">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-credit-card c3-form-field-wrapper-stripe-payment">
            <label for="cardElement">{{$t('label.creditCard')}}</label>
            <div id="cardElement" class="input" ref="stripeElement"></div>
        </div>
        <div class="c3-form-field-group-error-message" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
    import {StripeMixin} from './mixin/stripe';

    export default {
        mixins: [StripeMixin],
        data() {
            return {
                paymentType: 'card',
            }
        },
    };
</script>
