import {AbstractBaseFactory} from '@/domain/factory/abstractBaseFactory';
import {integer, required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
    ...AbstractBaseFactory,
    create: (fields) => {
        let billingAddress = {
            company: '',
            name: '',
            address: {
                street: '',
                zip: '',
                city: '',
                country: null,
            }
        };

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, billingAddress);
        }

        return billingAddress;
    },
    createValidation: (fields) => {
        let validation = {
            company: {required},
            name: {required},
            address: {
                street: {
                    required,
                    maxLength: maxLength(100)
                },
                zip: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(30)
                },
                city: {
                    required,
                    maxLength: maxLength(100)
                },
                country: {
                    required,
                    integer,
                }
            }
        };

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, validation);
        }

        return validation;
    },
};
