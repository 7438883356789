<template>
    <div class="c3-content-customer-registration">
        <div class="c3-content-customer-registration-description">
            <c3-text-with-icon icon="c3-icon-credit-card" :title="$t('pages.customer.profile.payment.title')">
                {{ $t('pages.customer.profile.payment.info') }}
            </c3-text-with-icon>
        </div>
        <div class="c3-content-customer-registration-description-form">
            <c3-form-payment-details @updated="paymentDataUpdated" :buttonLabel="$t('label.closeBtn')"/>
        </div>
    </div>
</template>

<script>
    import C3FormPaymentDetails from '@/components/content/customer/PaymentDetailsForm.vue';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';

    export default {
        components: {
            C3FormPaymentDetails,
            C3TextWithIcon
        },
        data() {
            return {}
        },
        computed: {},
        methods: {
            paymentDataUpdated() {
                if (this.$route.query.redirectAfterSave) {
                    this.$router.push(this.$route.query.redirectAfterSave);
                } else {
                    return {name: 'CustomerProfileBaseData'};
                }
            },
        },
        created() {
            this.$store.commit('view/showLoading');
            this.$store.dispatch('user/loadCurrentPayment')
                .then(() => {
                    if (this.$store.getters['user/payment']) {
                        this.paymentDataUpdated();
                    }
                })
                .finally(() => this.$store.commit('view/hideLoading'));
        },
    };
</script>
<style lang="scss" scoped>
    .c3-payment-state {
        display: block;
        padding-top: 30px;
        margin-bottom: 30px;
    }

    .c3-form-wrapper {
        padding-top: 15px;
    }
</style>