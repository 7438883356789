<template>
    <div class="c3-content-customer-registration">
        <div class="c3-content-customer-registration-description">
            <c3-text-with-icon icon="c3-icon-thumbs-up" :title="$t('pages.customer.registration.finish.title')">
                <p>{{ $t('pages.customer.registration.finish.text') }}</p>
            </c3-text-with-icon>
        </div>
        <div class="c3-content-customer-registration-finished">
            <div v-if="isTenantCoupocket" class="c3-content-customer-registration-finished-coupon">
                {{ $t('pages.customer.registration.finish.couponIntro') }}
                <router-link :to="{name: 'CouponCreateWizard'}">
                    <c3-button :label="$t('coupon.create')"/>
                </router-link>
            </div>
            <div class="c3-content-customer-registration-finished-bonus-card">
                {{ $t('pages.customer.registration.finish.bonusCardIntro') }}
                <router-link :to="{name: 'BonusCardWizard'}">
                    <c3-button :label="$t('bonusCard.create')"/>
                </router-link>
            </div>
            <div class="c3-content-customer-registration-finished-bonus-card">
                {{ $t('pages.customer.registration.finish.customerStoriesIntro') }}
                <router-link :to="{name: 'CustomerStoreList'}">
                    <c3-button :label="$t('store.create')" styling="4"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';

    export default {
        components: {C3Button, C3TextWithIcon, C3TitleWithSubtitle},
    };
</script>

<style lang="scss" scoped>
    .c3-content-customer-registration-finished {
        @include horizontalSpacing();
        padding-bottom: 30px;
        background-color: $secondary-color;
        text-align: center;

        .c3-button {
            margin-top: 15px;
        }
    }

    .c3-content-customer-registration-finished-coupon,
    .c3-content-customer-registration-finished-bonus-card {
        padding-top: 30px;
    }
</style>
