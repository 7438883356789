var IBAN = require('iban');
import {required, requiredIf, minLength} from 'vuelidate/lib/validators'
import {Config} from '@/config';

const ibanValidator = (value, vm) => {
    if (vm.selectedPayment !== PAYMENT.TYPE.IBAN) {
        return true;
    }

    return IBAN.isValid(value);
};

const sepaDirectDebitMandateValidator = (value, vm) => {
    if (vm.selectedPayment !== PAYMENT.TYPE.IBAN) {
        return true;
    }
    return true === value;
};

export default {
    create: () => {
        return {
            ownerName: '',
            selectedPayment: '',
            paymentData: null,
            iban: null,
            sepaDirectDebitMandate: false,
        }
    },
    createValidation: () => {
        return {
            ownerName: {
                required,
                minLength: minLength(4)
            },
            selectedPayment: {
                required
            },
            paymentData: {
                required: requiredIf(function (fields) {
                    return fields.selectedPayment !== PAYMENT.TYPE.IBAN;
                }),
            },
            iban: {
                required: requiredIf(function (fields) {
                    return fields.selectedPayment === PAYMENT.TYPE.IBAN;
                }),
                ibanValidator
            },
            sepaDirectDebitMandate: {
                sepaDirectDebitMandateValidator
            }
        }
    },
    createPaymentOptions: () => {
        const paymentOptions = [
            {
                label: 'Kreditkarte',
                value: PAYMENT.TYPE.CREDIT_CARD,
            }
        ];

        // Only available for coupocket, because CHF is not supported in SEPA direct debit
        if (Config.getTenant() === 'coupocket') {
            paymentOptions.push({
                label: 'Lastschrift',
                value: PAYMENT.TYPE.IBAN,
            });
        }

        return paymentOptions;
    },
};
