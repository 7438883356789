<template>
    <div class="c3-content-customer-registration">
        <div class="c3-content-customer-registration-description">
            <c3-text-with-icon icon="c3-icon-box-heart" :title="$t('pages.customer.profile.membership.chooseMembership')"/>
        </div>
        <c3-membership-select :membershipTypes="membershipTypes" :faqItems="faqItems" @submitted="goToNextStep"/>
    </div>
</template>

<script>
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3MembershipSelect from '@/components/content/customer/MembershipSelect';

    export default {
        components: {
            C3TextWithIcon,
            C3MembershipSelect
        },
        data() {
            return {};
        },
        computed: {
            membershipTypes() {
                return this.$store.state.membership.availableType;
            },
            faqItems() {
                return [
                    this.$store.getters['staticContent/singleContent'](7),
                    this.$store.getters['staticContent/singleContent'](8),
                    this.$store.getters['staticContent/singleContent'](9),
                    this.$store.getters['staticContent/singleContent'](10),
                    this.$store.getters['staticContent/singleContent'](11),
                ];
            },
            hasFreeMembership() {
                return this.$store.getters['user/hasFreeMembership']; 
            },
            nextStep() {
                // Force redirect event if payment data is missing
                if (this.$route.query.forceRedirectAfterSave) {
                    return this.$route.query.forceRedirectAfterSave;
                }

                if (this.$store.getters['user/payment'] || this.hasFreeMembership) {
                    // User already entered his payment data
                    if (this.$route.query.redirectAfterSave) {
                        return this.$route.query.redirectAfterSave;
                    } else {
                        return {name: 'CustomerProfileBaseData'};
                    }
                }

                return {name: 'CustomerRegistrationPayment', query: this.$route.query};
            }
        },
        methods: {
            async goToNextStep() {
                if (this.nextStep) {
                    this.$store.commit('view/showLoading');
                    await this.$store.dispatch('user/loadActiveMemberships');
                    this.$store.commit('view/hideLoading');

                    this.$router.push(this.nextStep);
                }
            }
        },
        created() {
            this.$store.commit('view/showLoading');
            Promise.all(
                [
                    this.$store.dispatch('membership/loadAvailableType', this.$store.getters['user/user'].id),
                    this.$store.dispatch('user/loadActiveMemberships'),
                    this.$store.dispatch('user/loadCurrentPayment'),
                    this.$store.dispatch('staticContent/loadContent')
                ]
            )
                .then(() => this.$store.commit('view/hideLoading'))
                .catch(() => this.$store.commit('view/hideLoading'));
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-content-padding .c3-content-text-with-icon {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;

        & /deep/ h2 {
            margin-top: 5px;
        }
    }

    .c3-badge-box {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

    }
</style>
